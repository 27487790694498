import React, { FunctionComponent, useEffect, useRef, useState } from "react"

import "./styles.scss"



const ImageRow: FunctionComponent<any> = ({ className = "", firstCol, secondCol, image, content, imageFirst=false, ...props }) => {

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []); 

  ////console.log('hello', height);

  // const textBlock = <div>{content}</div>

  const imageBlock = <img style={{maxHeight:`${height + 30}px`}} src={image} />

  return (
    <>
      {!imageFirst && (
        <div className={`row image-row y-align_row ${className}`} {...props}>
          <div className={`col-${firstCol} first-column `}  ref={elementRef} >
            {content}
          </div>
          <div className={`col-${secondCol} second-column y-align_item`}>
            {imageBlock}
          </div>
        </div>
      )}
      {imageFirst && (
        <div className={`row image-row y-align_row ${className}`} {...props}>
          <div className={`col-${firstCol} first-column y-align_item`}   >
          {imageBlock}
          </div>
          <div className={`col-${secondCol} second-column`} ref={elementRef}>
            {content}
          </div>
        </div>
      )}


    </>
  )
}

export default ImageRow
