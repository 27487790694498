import React, { useContext } from "react"
import Hero from "~/components/configurable/Hero"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import Section from "../configurable/Section"
import SectionNoAnim from "../configurable/SectionNoAnim"
import ImageRow from "../configurable/ImageRow"
import Col12 from "../grid/Col12"
import "./blank.scss"
import Col9 from "../grid/Col9"
import Col3 from "../grid/Col3"
import "./animation.scss"
import * as ReferIll from "~/vectors/refer.inline.svg"


import * as StringInv from "~/vectors/string_inv.inline.svg"
import * as HouseIllustration from "~/vectors/house-illustration.inline.svg"
import Col7 from "../grid/Col7"
import Col5 from "../grid/Col5"

const Blank = ({ location }) => {

  return (
    <div className="blank">
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          Blank
        </Heading>
      </Hero>
      <Block className="segBlock">
        <SectionNoAnim>
          <ImageRow
            firstCol={8}
            secondCol={4}
            image={require('../../images/doctors/card1.png')}
            content={<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Lorem, ipsum dolor sit amet consectetur adipisicing elit.  </p>}
          />
        </SectionNoAnim>    
        <SectionNoAnim>
          <ImageRow
            imageFirst={true}
            firstCol={4}
            secondCol={8}
            image={require('../../images/doctors/card1.png')}
            content={<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Lorem, ipsum dolor sit amet consectetur adipisicing elit.  </p>}
          />
        </SectionNoAnim> 
      </Block>

      
    </div>
  )
}

export default Blank
